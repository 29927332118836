
import {useEffect} from 'react';
import * as THREE from 'three'; 
import CounterAndText from './CounterAndText';

const Stats = () => { 

//     useEffect(() => {
//         // Initialize the scene, camera, and renderer
// const scene = new THREE.Scene();
// scene.background = new THREE.Color(0x282B34);
// const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
// const renderer = new THREE.WebGLRenderer({ antialias: true });
// renderer.setSize(window.innerWidth, window.innerHeight);
// renderer.shadowMap.enabled = true; // Enable shadows
// document.querySelector('.stats').appendChild(renderer.domElement);


// // Add lighting 
// const light = new THREE.DirectionalLight(0xffffff, 1);
// light.position.set(5, 5, 5); // Position the light
// light.castShadow = true; // Enable shadow casting
// scene.add(light);

// // Add a ground plane to receive shadows
// // const groundGeometry = new THREE.PlaneGeometry(20, 20);
// // const groundMaterial = new THREE.MeshStandardMaterial({ color: 0x48C0B0 });
// // const ground = new THREE.Mesh(groundGeometry, groundMaterial);
// // ground.rotation.x = -Math.PI / 2; // Rotate the plane to lay flat
// // ground.receiveShadow = true; // Enable shadow receiving
// // scene.add(ground);

// // Create a cube
// const cubeGeometry = new THREE.BoxGeometry(3, 3, 3);
// const cubeMaterial = new THREE.MeshStandardMaterial({ color: 0x282B34 });
// const cube = new THREE.Mesh(cubeGeometry, cubeMaterial);
// cube.castShadow = true; // Enable shadow casting for the cube
// cube.position.y = 0; // Position the cube above the ground
// // cube.position.x = 0; // Position the cube above the ground
// scene.add(cube);

// // Set camera position
// camera.position.z = 5;

// // Animation loop
// function animate() {
//   requestAnimationFrame(animate);

//   // Rotate the cube
// //   cube.rotation.x += 0.01;
//   cube.rotation.y += 0.005;

//   // Render the scene
//   renderer.render(scene, camera);
// }

// // Adjust canvas on window resize
// window.addEventListener("resize", () => {
//   const width = window.innerWidth;
//   const height = window.innerHeight;

//   renderer.setSize(width, height);
//   camera.aspect = width / height;
//   camera.updateProjectionMatrix();
// });

// // Start the animation loop
// animate();


//     }, [])



    
    return(
        <div className='stats'>
            <CounterAndText countTo={4} addPlus={false} text={"Public Apps"} i={0}></CounterAndText>
            <CounterAndText countTo={20} addPlus={true} text={"Private Apps"} i={1}></CounterAndText>
            <CounterAndText countTo={100} addPlus={true} text={"Brands And Clients"} i={2}></CounterAndText>
        </div>
    )
}

export default Stats;