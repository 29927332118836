import './App.css';
import Intro from './components/Intro';
import WhoIAm from './components/WhoIAm';


import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import Index from './pages/Index';
import Blogs from './pages/Blogs';
import Sightly from './pages/Sightly';
import EasyEdits from './pages/EasyEdits';
import BlogPost from './components/BlogPost';
import Latch from './pages/Latch';
import ThreeDTesting from './pages/ThreeDTesting';


function App() {
  return (
    <div className="App">
    <Router>
    <Routes>
        <Route exact path='/' element={<Index/>} />
        <Route exact path='/blogs' element={<Blogs/>} />
        <Route path='blogs/:id' element={<BlogPost/>} />
        <Route exact path='/latch' element={<Latch/>} />
        <Route exact path='/sightly' element={<Sightly/>} />
        <Route exact path='/easy-edits' element={<EasyEdits/>} />
        <Route exact path='/three-d-testing' element={<ThreeDTesting/>} />

    </Routes>
    </Router>

    </div>
  );
}

export default App;
