import * as THREE from "three";

const {useEffect, useRef} = require("react");

const ThreeDTesting = () => {

//     useEffect(() => { 
//         // Set up the scene, camera, and renderer
// const scene = new THREE.Scene();
// const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
// const renderer = new THREE.WebGLRenderer();
// renderer.setSize(window.innerWidth, window.innerHeight);
// document.body.appendChild(renderer.domElement);

// // Add lighting
// const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
// scene.add(ambientLight);

// const spotlight = new THREE.SpotLight(0xffffff, 1);
// spotlight.position.set(0, 10, 5);
// spotlight.castShadow = true;
// scene.add(spotlight);

// // Create the wall
// const wallGeometry = new THREE.BoxGeometry(10, 5, 0.1);
// const wallMaterial = new THREE.MeshStandardMaterial({ color: 0xcccccc });
// const wall = new THREE.Mesh(wallGeometry, wallMaterial);
// wall.position.z = -5;
// scene.add(wall);

// // Add paintings (as simple planes with textures)
// const paintings = [];
// const paintingTextures = [
//     fixable4
// ]; // Replace with actual image URLs

// paintingTextures.forEach((textureURL, index) => {
//   const loader = new THREE.TextureLoader();
//   loader.load(textureURL, (texture) => {
//     const paintingGeometry = new THREE.PlaneGeometry(2, 2);
//     const paintingMaterial = new THREE.MeshStandardMaterial({ map: texture });
//     const painting = new THREE.Mesh(paintingGeometry, paintingMaterial);

//     painting.position.x = -4 + index * 4; // Arrange paintings horizontally
//     painting.position.z = -4.95; // Slightly offset to be in front of the wall
//     paintings.push(painting);
//     scene.add(painting);
//   });
// });

// // Camera position
// camera.position.z = 10;

// // Animate the scene
// function animate() {
//   requestAnimationFrame(animate);

//   // Slowly move the camera left and right
//   const time = Date.now() * 0.001;
//   camera.position.x = Math.sin(time) * 5;
//   camera.lookAt(0, 0, -5); // Keep the camera focused on the wall

//   renderer.render(scene, camera);
// }

// // Adjust canvas on window resize
// window.addEventListener("resize", () => {
//   const width = window.innerWidth;
//   const height = window.innerHeight;

//   renderer.setSize(width, height);
//   camera.aspect = width / height;
//   camera.updateProjectionMatrix();
// });

// // Start animation
// animate();

    //  }, []); 

    return(
        <>
\
        </>
    )
}

export default ThreeDTesting;