import latchLogo from '../public/latch-logo.png';
import letchDemo from '../public/latch-demo.gif';
import performance from '../public/performance.png';
import upsellDemo from '../public/Latch-Upsell.png';
import plans from '../public/plans.png';
import { motion, useScroll } from "framer-motion"
import Card from '../components/Card';


// 43F2BC
const Latch = () => {

    const { scrollYProgress } = useScroll();

    return (
        <div className='latchPage'>
              {/* <motion.div
    animate={{
      scale: [1, 2, 2, 1, 1],
      rotate: [0, 0, 270, 270, 0],
      borderRadius: ["20%", "20%", "50%", "50%", "20%"],
    }}
  >HI THERE</motion.div> */}
            <motion.div
            layout
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                >
                <h1 className='glower' style={{marginBottom:'10px', fontSize:"12rem"}}>Latch</h1>
                <p>A Shopify App built to sercure member exclusive products and upsell non-members.</p>
                <img style={{marginTop:'10px'}} width={300} src={latchLogo}  alt="Latch Logo" className='latchLogo' ></img>
            </motion.div>
            <div>

                <a href='https://apps.shopify.com/member-lock' target='_blank'>
                <motion.button whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }} className='appStoreBtn'>View On The App Store</motion.button>
                </a>
            </div>
            <motion.div
                            initial={{ opacity: 0, scale: .8 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}>
                <img style={{marginTop:'5%'}} width={1000} src={letchDemo}  alt="Latch Demo GIF" className='latchGif' ></img>
            </motion.div>
            <motion.div 
                initial={{ opacity: 0, scale: .8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{width:'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto'}}
            >
                <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>Don't lose sales to security.</h2>
                <p>Limit products to only be available to subscribers with certain tags. Use that F.O.M.O to create new subscribers by providing easy checkout options to bundle the item they want and the subscription they need.</p>            
                <img style={{marginTop:'5%'}} width={1000} src={upsellDemo}  alt="upsellDemo" className='performanceImg' ></img>
            </motion.div>
            <motion.div 
                initial={{ opacity: 0, scale: .8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{width:'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto'}}>
                <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>Directly track upsell revenue.</h2>
                <img style={{marginTop:'5%'}} width={1000} src={performance}  alt="Performance" className='performanceImg' ></img>
            </motion.div>
            <motion.div 
                initial={{ background: "#282C34" }}
                whileInView={{ background: "#fff" }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto', marginTop:"5%"}}>
                <h2 style={{color:'#282C34', fontSize:'5rem', marginTop:"5%", marginBottom:'3%'}}>Plans for <motion.span
                    initial={{ color: "#282C34" }} whileInView={{ color: "#43F2BC" }} transition={{ duration: 2 }} viewport={{ once: true }}>EVERYONE</motion.span>
                </h2>
                <img style={{marginTop:'1%', marginBottom:'4%'}} width={1200} src={plans}  alt="plans" className='plansImg' ></img>
                <div className='btn-container btn-2'>
                    <a href='https://apps.shopify.com/member-lock' target='_blank'>
                    <motion.button whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }} className='appStoreBtn'>View On The App Store</motion.button>
                    </a>
                </div>
            </motion.div>
        </div>
    )
}

export default Latch;