import React from "react";
import { motion, useScroll } from "framer-motion";
import { Helmet } from "react-helmet";
import sightlyLogo from "../public/sightly.svg";
import sightlyDemo from "../public/Sightly-Demo-One.mp4";
import sightlyHistoryDemo from "../public/Sightly-Demo-Two.mp4";
import sightlyExample from "../public/sightly-example.png";

const Sightly = () => {
    const { scrollYProgress } = useScroll();

    return (
        <>
            {/* SEO Metadata */}
            <Helmet>
                <title>Sightly | AI-Powered Reporting for Shopify</title>
                <meta
                    name="description"
                    content="Sightly offers AI-driven reporting and analytics for Shopify stores. Get actionable insights and simplify your store management."
                />
                <meta name="keywords" content="AI reporting, Shopify analytics, eCommerce insights, Sightly app" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Helmet>

            <div className="sightlyPage">
                {/* Hero Section */}
                <header>
                    <motion.div
                        layout
                        initial={{ opacity: 0, scale: 1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1 }}
                    >
                        <h1 className="glower" style={{ marginBottom: "10px", fontSize: "12rem" }}>
                            Sightly
                        </h1>
                        <p>A.I. powered reporting and analytics with conversational questions.</p>
                        <img
                            src={sightlyLogo}
                            alt="Sightly logo showcasing AI reporting for Shopify"
                            style={{ marginTop: "10px", width: "300px" }}
                        />
                    </motion.div>
                    <div>
                        <a
                            // href="mailto:dev@michaelbuildsapps.com?subject=Sightly Beta Access&body=Hi, I'd like to to enroll in Beta access to Sightly!"
                            href="https://apps.shopify.com/sightly"
                            aria-label="Request beta access to Sightly"
                        >
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="appStoreBtn"
                                style={{ marginTop: "1rem" }}
                            >
                                View On The App Store
                            </motion.button>
                        </a>
                        <p style={{marginBottom:'3rem'}}>(Currently only available for Beta users)</p>
                    </div>
                </header>

                {/* Demo Section */}
                <section>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >
                        <video
                            muted
                            autoPlay
                            loop
                            playsInline
                            width={1000}
                            src={sightlyDemo}
                            alt="Sightly demo showcasing AI-powered Shopify reporting"
                            className="latchGif"
                            style={{ width: "100%", marginTop: "3rem" }}

                        />
                    </motion.div>
                </section>

                {/* Features Section */}
                <section style={{ textAlign: "center", margin: "auto", padding: "2rem" }}>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        style={{width:'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto'}}

                    >
                        <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>Answers to Every Question</h2>
                        <p>
                            Speak to Sightly in a conversational manner, asking any question about products, orders,
                            customers, and more. Get actionable insights effortlessly.
                        </p>
                        <img
                            className="performanceImg"
                            src={sightlyExample}
                            alt="Example report generated by Sightly"
                            style={{ width: "100%", marginTop: "3rem" }}
                            loading="lazy"
                        />
                    </motion.div>
                </section>

                {/* Data Visualization Section */}
                <section>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        whileInView={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                    >
                        <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>Data Visualization in Seconds</h2>
                        <p>
                            Create custom charts, graphs, and CSVs. Save and compare historical data with ease.
                        </p>
                        <video
                        className='latchGif'
                            muted
                            autoPlay
                            loop
                            playsInline
                            src={sightlyHistoryDemo}
                            alt="Sightly demo for data visualization"
                            style={{ width: "100%", marginTop: "3rem", marginBottom: "6rem" }}
                            loading="lazy"
                        />
                    </motion.div>
                </section>

                {/* Beta Access CTA */}
                <footer>
                    <motion.div
                        initial={{ background: "#282C34" }}
                        whileInView={{ background: "#fff" }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "2rem",
                            
                        }}
                    >
                        <h2 style={{color:'#282C34', fontSize:'5rem', marginTop:"5%", marginBottom:'3%'}}>
                            Beta Access Is{" "}
                            <motion.span
                                initial={{ color: "#282C34" }}
                                whileInView={{ color: "#43F2BC" }}
                                transition={{ duration: 2 }}
                            >
                                OPEN
                            </motion.span>
                        </h2>
                        <p style={{color:'#282C34'}}>Reach out to us and we'll set you up with free beta access until public release.</p>
                        <a
                            // href="mailto:dev@michaelbuildsapps.com?subject=Sightly Beta Access&body=Hi, I'd like to to enroll in Beta access to Sightly!"
                            href="https://apps.shopify.com/sightly"
                            aria-label="Contact us for beta access"
                            style={{marginTop:'3rem', marginBottom:"10rem"}}
                        >
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                className="appStoreBtn"
                            >
                                View On The App Store
                            </motion.button>
                        </a>
                    </motion.div>
                </footer>
            </div>
        </>
    );
};

export default Sightly;
