
import avatar from '../public/avatar.svg';

const Card = ({ author, description, image }) => {
  return (
    <div className="card" >
      {/* <img src={image} alt={title} /> */}
      <p style={{color:"#282C34", fontSize:"1.2rem"}}>{description}</p>
      <h3 style={{fontWeight:"bold"}}></h3>
      <br></br>
      <div style={{ width:'50%', margin:"auto" ,borderTop:"1px solid #282C34"}}></div>
      <div style={{display:'flex', flexDirection:"column", justifyContent:"flexStart", alignItems:'center'}}>
        <br></br>
        <h4 style={{margin:"0px"}}>{author}</h4>
        <br></br>
        <img src={avatar} width={50}></img>
      </div>
    </div>
  );
}

export default Card;