import easyEditsLogo from '../public/easy-edits.svg';
import easyEditsDemo1 from '../public/Easy-Edits-Demo-1.mp4';
import easyEditsDemo2 from '../public/Easy-Edits-Demo-2.mp4';
import easyEditsPic from '../public/Easy-Edits-Demo.png';
import plans from '../public/plans.png';
import { motion, useScroll } from "framer-motion"
import Card from '../components/Card';


// 43F2BC
const EasyEdits = () => {

    const { scrollYProgress } = useScroll();

    return (
        <div className='easyEditsPage'>
              {/* <motion.div
    animate={{
      scale: [1, 2, 2, 1, 1],
      rotate: [0, 0, 270, 270, 0],
      borderRadius: ["20%", "20%", "50%", "50%", "20%"],
    }}
  >HI THERE</motion.div> */}
            <motion.div
            layout
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                >
                <h1 className='glower' style={{marginBottom:'10px', fontSize:"12rem"}}>Easy Edits</h1>
                <p>The click and change editor you wish Shopify had.</p>
                <img style={{margin:'50px'}} width={200} src={easyEditsLogo}  alt="Latch Logo" className='easyEditsLogo' ></img>
            </motion.div>
            <div>

                <a href='https://apps.shopify.com/easy-edits' target='_blank'>
                <motion.button whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }} className='appStoreBtn'>View On The Shopify App Store</motion.button>
                </a>
            </div>
            <motion.div
                            initial={{ opacity: 0, scale: .8 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 1 }}
                            viewport={{ once: true }}>
                <video muted="true" autoplay="true" loop="true" style={{marginTop:'5%'}} width={1000} src={easyEditsDemo1}  alt="Easy Edits Demo" className='latchGif' ></video>
            </motion.div>
            <motion.div 
                initial={{ opacity: 0, scale: .8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{width:'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto'}}
            >
                <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>Don't waste time & <span style={{color:"#2bc463"}}>money</span>.</h2>
                <p>Editing your stores theme is now in your hands. No digging through code. No expensive freelancers or agencies.</p>            
            </motion.div>
            <br></br>
            <div className='threeGridContainer'>
                <Card author={"Huracan Fabrication"} description={`Highly recommended! I spent hours trying to find a solution to my issue by editing code. With easy edits I literally had it done in 5 minutes! Thank you for your help`}/>
                <Card author={"zutBe"} description={`I spent three weeks trying to change the menu colors on my Shopify store. What seemed like a simple task turned out to be much more complex. I spent hours on forums, copying and pasting code into CSS, Liquid, and other files, but nothing worked. Then I found Easy Edits. Within five minutes of downloading and watching a quick video, I was editing, and in seven minutes, I was done. I wish I had found Easy Edits sooner. Thank you for creating this app!`}/>
                <Card author={"thursday books"} description={`This app helps to change my storefront, pages, and product pages effortlessly. I'm so happy to have found easy edits. The support for the app is excellent. I'd highly recommend using this app for your design needs.`}/>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div>
                <a href='https://apps.shopify.com/easy-edits/reviews' target='_blank'>
                <motion.button whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }} className='appStoreBtn'>View Reviews On The App Store</motion.button>
                </a>
            </div>
            <motion.div 
                initial={{ opacity: 0, scale: .8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{width:'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto'}}>
                <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>See it? Change it.</h2>
                <img style={{marginTop:'5%'}} width={1000} src={easyEditsPic}  alt="See it? Change it." className='performanceImg' ></img>
            </motion.div>
            <motion.div 
                initial={{ opacity: 0, scale: .8 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{width:'80%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto'}}>
                <h2 style={{color:'#fff', fontSize:'5rem', marginTop:"10%", marginBottom:'3%'}}>Multiple edits & multiple themes.</h2>
                <video muted="true" autoplay="true" loop="true" style={{marginTop:'5%'}} width={1000} src={easyEditsDemo2}  alt="Easy Edits Demo" className='latchGif' ></video>
            </motion.div>
            <motion.div 
                initial={{ background: "#282C34" }}
                whileInView={{ background: "#fff" }}
                transition={{ duration: 1 }} 
                viewport={{ once: true }}
                style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', margin:'auto', marginTop:"5%"}}>
                <h2 style={{color:'#282C34', fontSize:'5rem', marginTop:"5%", marginBottom:'3%'}}>Try it free, keep your edits.
                </h2>
                <p style={{color:'#282C34'}} color=''>Easy Edits is <span style={{textDecoration:'underline'}}>$6.99</span> a month.</p>
                <br></br>
                <p style={{color:'#282C34'}} color=''>Try it free for two weeks. Make as many edits as you'd like and still keep them if you want to delete the app.</p>
                <br></br>
                <br></br>
                <div className='btn-container btn-2'>
                    <a href='https://apps.shopify.com/easy-edits' target='_blank'>
                    <motion.button whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }} className='appStoreBtn'>View On The Shopify App Store</motion.button>
                    </a>
                </div>
            </motion.div>
        </div>
    )
}

export default EasyEdits;